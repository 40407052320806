@font-face {
  font-family: 'JennaSue';
  src: url('../assets/JennaSue.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.home {
  padding-top: 60px; /* Adjust padding to prevent header overlap */
}

.hero-section {
  position: relative;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Hide overflow */
}

.hero-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 1s ease-out, opacity 1s ease-out;
}

/* Centering image in writing section */
.emblem-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: -40px;
}

.emblem-container img {
  max-width: 85%;
  height: auto;
}

.emblem-write {
  text-align: center;
  color: white;
  font-family: "JennaSue", sans-serif;
  font-size: 5rem;
  margin-top: 80px;
}

.writing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px;
}

.writing-container img {
  max-width: 100%;
  height: auto;
}

.hero-image.animate {
  transform: translateY(-20px);
  opacity: 0.8;
}

.hero-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  transition: opacity 1s ease-out;
}

.hero-overlay.animate {
  opacity: 0;
}

/* New styles for categories section */
.categories-section {
  padding: 20px 0;
  background-color: #004c91;
  margin-top: -25px;
}

.section-title {
  text-align: center;
  margin-left: -100px;
  font-size: 1.0rem;
  color: white;
}

.categories-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.category-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px; /* Adjusted width for better mobile view */
  height: 200px;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.category-card-container.animate {
  opacity: 1;
  transform: translateX(0);
}

.category-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.5s ease;
  text-decoration: none;
  color: inherit;
}

.category-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.category-card-title {
  margin: 10px 0;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
}

.category-card:hover {
  transform: translateY(-5px);
}

/* Animation keyframes */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.home-sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.home-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 600px;
  margin: 20px 0;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.home-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.home-section h2 {
  margin-top: 0;
}

.home-section p {
  margin-bottom: 0;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s;
}

header.hidden {
  top: -80px; /* Adjust this value based on header height */
}

.about-container2 {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px; /* Adjusted padding for better mobile view */
}

.about-container2 p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.about-container2 p:first-of-type {
  font-style: italic;
}


/* Media Queries */
@media (min-width: 600px) {
  .home-title {
    font-size: 4rem;
  }
  .categories-section h2 {
    text-align: left;
    margin-left: 430px; /* Adjusted for larger screens */
  }
  .category-card-container {
    width: 300px;
    height: 250px;
  }

  .emblem-container {
    margin-top: 60px;
  }

  .emblem-container img {
      max-width: 35%;
  }

  .emblem-write {
    margin-top: 50px;
    font-size: 6rem;
  }

  .about-container2 {
    padding-top: 20px;
  }
  .about-container2 h1 {
    font-size: 2.5rem;
  }
  .about-container2 p {
    font-size: 1.2rem;
  }
}
