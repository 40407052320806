.contact {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 10px;
  padding-top: 120px; /* Adjusted padding for better mobile view */
}

.contact h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.contact form {
  display: flex;
  flex-direction: column;
}

.contact input, .contact textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #004c91;
  color: white;
  cursor: pointer;
}

.contact button:hover {
  background-color: #004080;
}

/* Media Queries */
@media (min-width: 600px) {
  .contact {
    max-width: 600px;
    padding-top: 200px;
  }
  .contact h1 {
    font-size: 2rem;
  }
}
