.admin-products {
  padding: 20px;
  padding-top: 200px; /* Padding for top spacing on computer screens */
}

.admin-product-list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between product items */
}

.admin-product-item {
  padding: 20px; /* Padding for product items */
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9; /* Background color for product items */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for product items */
  text-align: center; /* Center text */
}

.admin-product-item h3 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
  flex: 1; /* Allow flex growth */
  text-align: center; /* Center text */
}

.admin-product-item p {
  margin: 5px 0;
  color: #555;
  flex: 1; /* Allow flex growth */
  text-align: center; /* Center text */
}

.stock-management {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center; /* Center content */
}

.stock-management button {
  padding: 5px 10px;
  background-color: #004c91;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.stock-management button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.stock-management span {
  font-weight: bold;
  text-align: center; /* Center text */
}

.stock-management button:hover:not(:disabled) {
  background-color: #004080;
}

.admin-product-form {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center text */
}

.admin-product-form h3 {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
  text-align: center; /* Center text */
}

.admin-product-form input {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: calc(100% - 22px);
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: border-color 0.3s;
}

.admin-product-form input:focus {
  border-color: #004080;
}

.admin-product-form button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-product-form button:hover {
  background-color: #218838;
}
