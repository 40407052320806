.products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusted for smaller screens */
  gap: 20px;
  padding: 20px;
  max-width: 100%;
  margin: 60px auto; /* Adjusted for better mobile view */
  padding-top: 80px; /* Adjust according to the height of your header */
}

.product-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, opacity 1s ease-out, transform 1s ease-out;
  text-align: center;
  text-decoration: none; /* Remove underline */
  color: inherit;
  opacity: 0;
  transform: translateY(20px);
}

.product-card.animate {
  opacity: 1;
  transform: translateY(0);
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image-container {
  width: 100%;
  height: 200px; /* Adjusted for better mobile view */
  position: relative; /* Required for absolute positioning of hover image */
  overflow: hidden; /* Ensure the image fits within the container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image, .product-image-hover {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

.product-image-hover {
  opacity: 0;
}

.product-card:hover .product-image {
  opacity: 0;
}

.product-card:hover .product-image-hover {
  opacity: 1;
}

.product-info {
  padding: 10px;
}

.product-name {
  font-size: 0.8rem;
  margin: 10px 0;
  color: #333;
  text-decoration: none; /* Remove underline */
}

.product-price {
  font-size: 0.6rem;
  margin: 10px 0;
  color: #666;
}

.product-card a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color */
}

.product-card a:hover {
  text-decoration: none; /* Remove underline */
}

/* Media Queries */
@media (min-width: 600px) {
  .products {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 1200px;
    margin: 100px auto;
    padding-top: 120px;
  }
  .product-image-container {
    height: 250px;
  }
  .product-name {
    font-size: 1.0rem;
  }
  .product-price {
    font-size: 0.6rem;
  }
}
