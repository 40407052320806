.footer {
  background-color: #ffffff; /* White background */
  color: #004c91;
  text-align: center;
  padding: 20px;
  border-top: 1px solid #e0e0e0; /* Light border for separation */
  padding-top: 20px;
  font-size: 0.9rem; /* Adjusted for better mobile view */
}

/* Media Queries */
@media (min-width: 600px) {
  .footer {
    font-size: 1rem;
  }
}
