@font-face {
  font-family: 'Alpha Echo';
  src: url('../assets/alph.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
.about-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 120px; /* Adjusted padding for better mobile view */
}

.about-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #004c91;
  font-family: "Alpha Echo", serif;
  font-weight: 500;
  line-height: 1.2;
}

.about-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #333;
}

.about-container p:first-of-type {
  font-style: italic;
}

/* Media Queries */
@media (min-width: 600px) {
  .about-container {
    padding-top: 200px;
  }
  .about-container h1 {
    font-size: 2.5rem;
  }
  .about-container p {
    font-size: 1.2rem;
  }
}
