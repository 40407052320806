@font-face {
  font-family: 'TropilineSans-Regular';
  src: url('./TropilineSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TropilineSans-Regular';
  src: url('./TropilineSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* You can add more font weights if needed */

body {
  font-family: 'TropilineSans-Regular', sans-serif;
}
