.admin-orders {
  padding-top: 200px;
}

.admin-orders h1 {
  text-align: center;
  margin-bottom: 20px;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.orders-table th, .orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.orders-table th {
  background-color: #f2f2f2;
}

.orders-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.orders-table tr:hover {
  background-color: #ddd;
}

.orders-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #004c91;
  color: white;
}
