.validate-order {
  text-align: center;
  margin: 2rem auto;
  max-width: 600px;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding-top: 200px;
}

.order-details {
  margin-top: 1rem;
  text-align: left;
}

.order-details p {
  margin: 0.5rem 0;
}

.order-details button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #004c91;
  color: white;
}

.order-details button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
