.product-detail {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  max-height: 100%;
  margin: 60px auto;
  padding-top: 80px;
  gap: 20px;
}

.product-detail-image-container {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: -10px; /* Adjust to move closer to the image */
  width: 100%;
  position: relative; /* Added for better positioning control */
}

.image-nav-arrow {
  background-color: transparent;
  border: none;
  color: #003366;
  cursor: pointer;
  font-size: 1.5rem;
}

.image-nav-arrow:focus, .image-nav-arrow:active, .image-nav-arrow:hover {
  background-color: transparent;
  color: #003366;
  outline: none;
}

.image-nav-arrow.left {
  margin-right: 0;
}

.image-nav-arrow.right {
  margin-left: 0;
}

.image-dots {
  display: flex;
  gap: 10px;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #003366;
}

.product-detail-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.product-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.product-price {
  font-size: 1.2rem;
  color: #666;
}

.size-selector, .quantity-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.size-buttons, .quantity-buttons {
  display: flex;
  gap: 10px;
}

.size-button, .quantity-button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #003366;
  cursor: pointer;
}

.size-button.selected {
  border-color: #003366;
  background-color: #003366;
  color: #fff;
}

.quantity {
  padding: 10px 10px;
  font-size: 1rem;
}

.add-to-cart, .buy-now {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1rem;
}

.add-to-cart:hover, .buy-now:hover {
  background-color: #005599;
}

.additional-info {
  margin-top: 20px;
}

.product-composition, .product-about, .engagements {
  margin: 20px 0;
}

.engagement-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.engagement-icon {
  width: 100px;
  height: 80px;
}

@media (min-width: 800px) {
  .product-detail {
    flex-direction: row;
    gap: 40px;
    max-width: 1200px;
    margin: 100px auto;
    padding-top: 120px;
  }

  .image-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%; /* Adjust as needed */
  }

  .product-detail-image-container {
    width: 100%;
    height: 600px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-navigation {
    margin-top: -30px;
  }

  .image-nav-arrow {
    font-size: 1.3rem;
    padding: 10px; /* Added padding to increase hitbox size */
    position: absolute; /* Added to position arrows correctly */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center vertically */
  }

  .image-nav-arrow.left {
    margin-right: 80px;
  }

  .image-nav-arrow.right {
    margin-left: 80px;
  }

  .product-detail-info {
    width: 50%; /* Adjust as needed */
  }

  .product-name {
    font-size: 1.8rem;
  }

  .product-price {
    font-size: 1.4rem;
  }

  .engagement-icon {
    width: 150px;
    height: 100px;
  }
}
