/* General button styles */
.logout-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #004c91; /* Bootstrap Danger Color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  text-decoration: none;
}

/* Hover and active states */
.logout-button:hover, .logout-button:active {
  background-color: #004080; /* Darker shade of danger color */
}

/* Media query for responsive design */
@media (max-width: 600px) {
  .logout-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
