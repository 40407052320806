.confirmation-container {
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px; /* Center and limit the width */
}

.confirmation-logo {
  width: 100%;
  max-width: 300px; /* Adjusted for better mobile view */
  margin: 20px auto;
}

.confirmation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-home-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0070ba;
  color: white;
  text-decoration: none;
  border: none; /* Ensure button styling */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Ensure better readability */
}

.confirmation-home-link:hover {
  background-color: #005ea6;
}

.confirmation-qrcode {
  margin-top: 20px;
  width: 100%;
  max-width: 250px; /* Adjust width for better view */
  margin: 20px auto; /* Center the QR code */
}

p {
  font-size: 18px; /* Increase font size for better readability */
  margin: 10px 0;
}

strong {
  font-weight: bold;
}

/* Media Queries */
@media (min-width: 600px) {
  .confirmation-container {
    padding-top: 200px;
  }
  .confirmation-logo {
    max-width: 500px;
  }
  .confirmation-qrcode {
    max-width: 300px; /* Adjust width for larger screens */
  }
}
