.notification {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: #004c91;
  color: #fff;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
}

.notification.open {
  transform: translateX(0);
}

.notification h2 {
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.notification ul {
  list-style: none;
  padding: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  margin: 15px 0;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  background-color: #004c91;
  border-radius: 10px;
  padding: 10px;
}

.cart-item .image-container {
  width: 30%;
  height: 80px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: left;
}

.cart-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.item-details {
  flex: 1;
  margin-right: 10px;
}

.item-details p {
  margin: 0;
  color: #004c91;
}

.item-details p:nth-child(2),
.item-details p:nth-child(3) {
  color: #004c91;
  font-size: 14px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.quantity-controls button {
  background-color: #004c91;
  border: 1px solid #fff;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-controls span {
  margin: 0 10px;
  font-size: 16px;
  color: #004c91;
}

.remove-button {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 20px;
  cursor: pointer;
}

.notification .total {
  border-top: 1px solid #fff;
  padding-top: 10px;
  margin-top: 20px;
}

.notification .proceed-button {
  background-color: #fff;
  color: #004c91;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}

.notification .close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.notification-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: none;
}

.notification-background.open {
  display: block;
}

/* Media Queries */
@media (min-width: 600px) {
  .notification {
    width: 100%;
  }
}
