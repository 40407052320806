.cart {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 80px; /* Adjusted padding for better mobile view */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.cart h2 {
  text-align: center;
  margin-bottom: 20px;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  flex-direction: column; /* Adjusted for better mobile view */
  align-items: center;
  padding: 15px;
  background-color: #ffffff; /* White background for items */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for items */
}

.cart-item-image-container {
  width: 100px;
  height: 100px;
  overflow: hidden; /* Ensure the image doesn't overflow */
  border-radius: 10px; /* Match the item's rounded corners */
  margin-bottom: 10px; /* Adjusted margin for better spacing on mobile */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd; /* Light border for image container */
}

.cart-item-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image fits within the container */
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center aligned for better mobile view */
  text-align: center; /* Center text for better mobile view */
}

.cart-item-details h3 {
  margin: 0 0 10px;
  font-size: 1.2rem; /* Slightly larger font for titles */
}

.cart-item-details p {
  margin: 5px 0;
}

.cart-item-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

button:hover {
  background-color: #ffcc00;
  color: black; /* Ensure the text is readable */
}

.btn-remove-one {
  background-color: #004c91; /* Match header color */
}

.btn-remove-one:hover {
  background-color: #004080; /* Darker shade for hover */
}

.btn-remove {
  background-color: #004c91; /* Match header color */
}

.btn-remove:hover {
  background-color: #004080; /* Darker shade for hover */
}

.btn-clear-cart {
  background-color: #004c91; /* Match header color */
  margin-top: 20px;
  width: 100%;
}

.btn-clear-cart:hover {
  background-color: #004080; /* Darker shade for hover */
}

.cart-total {
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
}

/* Media Queries */
@media (min-width: 600px) {
  .cart {
    max-width: 800px;
    padding-top: 200px;
  }

  .cart-item {
    flex-direction: row; /* Row layout for larger screens */
    align-items: center;
  }

  .cart-item-details {
    text-align: left;
    align-items: flex-start;
  }

  .cart-item-image-container {
    margin-right: 20px; /* Margin-right for better spacing on larger screens */
    margin-bottom: 0; /* Remove bottom margin for larger screens */
  }
}
