/* Header.css */
.header {
  background-color: #004c91; 
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.menu-icon {
  cursor: pointer;
  color: white;
  font-size: 1.8rem;
  padding: 60px;
}

.header-center {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}

.header-logo {
  height: 90px; /* Adjusted height for the logo */
  padding: 0;
}

.header-logo img {
  height: 100%;
}

.cart-icon {
  display: flex;
  justify-content: flex-end;
  padding: 60px;
}

.cart-icon .fas{
  cursor: pointer;
  color: white;
  font-size: 1.8rem;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #004c91;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1001;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  justify-content: flex-end;
  padding: 60px;
  margin-right: -20px;
}

.sidebar-header .fas {
  color: white;
  cursor: pointer;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.sidebar-link {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.sidebar-link:hover {
  color: #004080;
}

.cart-icon {
  position: relative;
  cursor: pointer;
}

.cart-count {
  position: absolute;
  top: 82px;
  right: 50px;
  background-color: white;
  color: #004c91;
  border-radius: 100%;
  padding: 3px 7px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Media Queries */
@media (max-width: 768px) {
  .header-center {
    justify-content: flex-start;
  }

  .menu-icon {
    display: block;
    padding: 20px;
  }

  .header-logo {
    height: 60px;
  }

  .cart-icon {
    padding: 20px;
  }
}
