.checkout-form {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.checkout-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input[type="email"],
.form-group input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.pickup-option {
  display: flex;
  align-items: center;
}

.pickup-option label {
  margin-left: 5px;
}

.checkout-form button {
  width: 100%;
  padding: 10px;
  background-color: #004c91;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.checkout-form button:hover {
  background-color: #004080;
}

/* Media Queries */
@media (min-width: 600px) {
  .checkout-form {
    max-width: 400px;
  }
  .checkout-form h2 {
    font-size: 2rem;
  }
}
